<template>
  <div>
    <v-card>
      <v-toolbar flat color="transparent">
        <v-toolbar-title> Manage Regulatory Body </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list class="text-center">
          <v-text-field
            outlined
            dense
            label="Name*"
            v-model="item.name"
          ></v-text-field>
          <div v-if="item.logo && item.logo.data" class="mb-2">
            <v-avatar size="72">
              <v-img :src="item.logo.data"></v-img>
            </v-avatar>
          </div>
          <v-file-input
            @change="previewImage"
            v-model="logo"
            dense
            outlined
            label="Logo"
          >
          </v-file-input>
          <v-text-field
            outlined
            dense
            label="Website"
            v-model="item.website"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="API Documentation"
            v-model="item.apiDocumentation"
          ></v-text-field>
          <v-row justify="center">
            <v-btn
              color="primary"
              text
              :disabled="!item.name"
              @click="save"
              :loading="loading"
              >Submit</v-btn
            >
          </v-row>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data: () => ({
    loading: false,
    logo: null,
  }),
  methods: {
    async previewImage(file) {
      this.logoImage = URL.createObjectURL(this.logo);
      let url = await URL.createObjectURL(file);
      this.toDataUrl(url, (data) => {
        this.item.logo = {
          name: file.name,
          type: file.type,
          data,
        };
        console.log(this.item.logo);
        // this.submitIconUpload({
        //   name: file.name,
        //   data: data,
        //   type: file.raw.type,
        // });
      });
    },
    async save() {
      this.loading = true;
      if (this.item.id) {
        await this.$API.updateRegulatoryBody(this.item);
        this.$message.success("Successfully updated!");
      } else {
        await this.$API.createRegulatoryBody(this.item);
        this.$message.success("Successfully created!");
      }
      this.$emit("updated");
      this.loading = false;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
  },
};
</script>
